import React, { useContext } from 'react';
import './Portfolio.css';
import { PortfolioContext } from '../../context/PortfolioContext';
import { Link } from 'react-router-dom';
import { Portfolio as PortfolioType } from '../../types/Portfolio';

const Portfolio: React.FC = () => {
  const context = useContext(PortfolioContext);

  if (!context) {
    return <div>Loading...</div>;
  }

  const { portfolios } = context;

  return (
    <section id="portfolio" className="portfolio">
      <div className="container">
        <div className="section-title">
          <h2>Traction</h2>
          <p>Explore our diverse and impactful projects, each aiming to foster creativity and sustainability in the fashion industry.</p>
        </div>

        

        <div className="row portfolio-container">
          {portfolios.map((portfolio: PortfolioType) => (
            <div className={`col-lg-4 col-md-6 portfolio-item filter-${portfolio.category.toLowerCase().replace(/ /g, '-')}`} key={portfolio._id}>
              <div className="portfolio-wrap">
                <figure>
                  <img src={portfolio.thumbnail} className="img-fluid" alt="Portfolio" />
                  <Link to={`/portfolio/${portfolio._id}`} className="link-details" title="More Details">
                    <i className="bx bx-link"></i>
                  </Link>
                </figure>
                <div className="portfolio-info">
                  <h4><a href={`/portfolio/${portfolio._id}`}>{portfolio.title}</a></h4>
                  <p>{portfolio.category}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Portfolio;
