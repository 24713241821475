import React, { useState, useEffect } from 'react';
import './Hero.css';
import heroBg1 from '../../assets/img/hero-bg.jpg'; // Updated path
import heroBg2 from '../../assets/img/hero-bg1.jpg'; // Additional image
import logo from '../../assets/img/logo.png'; // FOTR logo

const Hero: React.FC = () => {
  const [currentBg, setCurrentBg] = useState(heroBg1);

  useEffect(() => {
    const backgrounds = [heroBg1, heroBg2];
    let currentIndex = 0;

    const interval = setInterval(() => {
      currentIndex = (currentIndex + 1) % backgrounds.length;
      setCurrentBg(backgrounds[currentIndex]);
    }, 5000); // Change image every 5 seconds

    return () => clearInterval(interval);
  }, []);

  return (
    <section
      id="hero"
      className="d-flex flex-column justify-content-center align-items-center"
      style={{ backgroundImage: `url(${currentBg})` }}
    >
      <div className="container text-center text-md-left" data-aos="fade-up">
        <h1>
          Welcome to <span>Fashion on the Road</span>
        </h1>
        <h2>Empowering Creativity and Sustainability in Kenyan Fashion</h2>
        <a href="#about" className="btn-get-started scrollto">
          Discover More
        </a>
      </div>
      <div className="logo-container">
        <img src={logo} alt="FOTR Logo" className="fotr-logo" />
      </div>
    </section>
  );
};

export default Hero;
