const BASE_URL = process.env.REACT_APP_API_URL || 'http://localhost:5888/api'; // Fallback in case .env is not set

export const fetchPortfolios = async () => {
  try {
    const response = await fetch(`${BASE_URL}/portfolios`);
    if (!response.ok) {
      throw new Error(`Failed to fetch portfolios: ${response.status} - ${response.statusText}`);
    }
    return await response.json();
  } catch (error) {
    if (error instanceof Error) {
      console.error('Error in fetchPortfolios:', error.message);
    } else {
      console.error('Unexpected error in fetchPortfolios:', error);
    }
    throw error; // Re-throw to let the calling function handle it
  }
};


export const fetchPortfolioById = async (id: string) => {
  try {
    const response = await fetch(`${BASE_URL}/portfolios/${id}`);
    if (!response.ok) {
      throw new Error(`Failed to fetch portfolio: ${response.status} ${response.statusText}`);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error in fetchPortfolioById:', error);
    throw error;
  }
};
