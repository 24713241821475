import React, { createContext, useState, useEffect, ReactNode } from 'react';
import { fetchPortfolios } from '../services/portfolioService';
import { Portfolio } from '../types/Portfolio';

interface PortfolioProviderProps {
  children: ReactNode;
}

interface PortfolioContextValue {
  portfolios: Portfolio[];
}

export const PortfolioContext = createContext<PortfolioContextValue | null>(null);

export const PortfolioProvider: React.FC<PortfolioProviderProps> = ({ children }) => {
  const [portfolios, setPortfolios] = useState<Portfolio[]>([]);

  useEffect(() => {
    fetchPortfolios().then(data => setPortfolios(data));
  }, []);

  return (
    <PortfolioContext.Provider value={{ portfolios }}>
      {children}
    </PortfolioContext.Provider>
  );
};
