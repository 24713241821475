import React from 'react';
import './Collections.css';
import fotrlouis from '../../assets/img/collections/fotrlouis.jpeg';
import fotrmwenesi from '../../assets/img/collections/fotrmwenesi.jpeg';
import fotrgina from '../../assets/img/collections/fotrgina.jpeg';
import fotrlad from '../../assets/img/collections/fotrlad.jpg';
import fotrlimited from '../../assets/img/collections/fotrlimited.jpeg';

const collectionsData = [
  {
    id: '1',
    image: fotrlouis,
    title: "FOTR Louis",
    description: "A collaboration between FOTR and Louis",
    url: "https://fotrshop.carolekinoti.co.ke/search?category=louis"
  },
  {
    id: '2',
    image: fotrmwenesi,
    title: "FOTR Mwenesi",
    description: "A collaboration between FOTR and Mwenesi",
    url: "https://fotrshop.carolekinoti.co.ke/search?category=mwenesi"
  },
  {
    id: '3',
    image: fotrgina,
    title: "FOTR Gina Din",
    description: "A collaboration between FOTR and Gina Din",
    url: "https://fotrshop.carolekinoti.co.ke/search?category=gina"
  },
  {
    id: '4',
    image: fotrlad,
    title: "FOTR LAD",
    description: "A collaboration between FOTR and LAD",
    url: "https://fotrshop.carolekinoti.co.ke/search?category=lad"
  },
  {
    id: '5',
    image: fotrlimited,
    title: "FOTR Limited Edition",
    description: "A collaboration between FOTR and Limited Edition",
    url: "https://fotrshop.carolekinoti.co.ke/search?category=limited"
  },
];

const Collections: React.FC = () => {
  return (
    <section id="collections" className="collections">
      <div className="container">
        <div className="section-title">
          <h2>Collections</h2>
          <p>Discover our unique collaborations and special editions.</p>
        </div>

        <div className="row">
          {collectionsData.map((collection) => (
            <div key={collection.id} className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4">
              <div className="icon-box">
                <div className="image">
                  <img src={collection.image} alt={collection.title} />
                </div>
                <h4><a href={collection.url} target="_blank" rel="noopener noreferrer">{collection.title}</a></h4>
                <p>{collection.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Collections;
