import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './PortfolioList.css';
import { fetchPortfolios } from '../../services/portfolioService';

const PortfolioList: React.FC = () => {
  const [portfolios, setPortfolios] = useState<any[]>([]);

  useEffect(() => {
    fetchPortfolios().then(data => setPortfolios(data));
  }, []);

  return (
    <section id="portfolio" className="portfolio">
      <div className="container">
        <div className="section-title">
          <h2>Portfolio</h2>
          <p>Sit sint consectetur velit quisquam cupiditate impedit suscipit</p>
        </div>
        <div className="row portfolio-container">
          {portfolios.map(portfolio => (
            <div className="col-lg-4 col-md-6 portfolio-item filter-app" key={portfolio._id}>
              <div className="portfolio-wrap">
                <figure>
                  <img src={portfolio.thumbnail} className="img-fluid" alt="Portfolio" />
                  <Link to={`/portfolio/${portfolio._id}`} className="link-details" title="More Details">
                    <i className="bx bx-link"></i>
                  </Link>
                </figure>
                <div className="portfolio-info">
                  <h4><a href={`/portfolio/${portfolio._id}`}>{portfolio.title}</a></h4>
                  <p>{portfolio.category}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default PortfolioList;
