import React from 'react';
import { useParams } from 'react-router-dom';
import './WhatWeDoDetail.css';
import Header from '../header/Header';
import Footer from '../footer/Footer';
import fotrImage from '../../assets/img/whatwedo/Fotr.26b499b03ea937f7b407.png';
import caravanImage from '../../assets/img/whatwedo/Caravan.1dafa20c8b0a271a9fd8.png';
import golfImage from '../../assets/img/whatwedo/golf.00a589de58142e15dafa.jpeg';
import conversationsImage from '../../assets/img/whatwedo/fotr-conv.4d07e55ba35e577e544e.jpeg';
import avpsImage from '../../assets/img/whatwedo/avps.jpg'; 
import pradaImage from '../../assets/img/whatwedo/prada.jpg'; 

const details: { [key: string]: { title: string, content: string, image: string } } = {
  'fotr-duka': {
    title: 'FOTR Duka',
    content: `
      A vibrant marketplace for designers to showcase and sell their creations.
    `,
    image: fotrImage,
  },
  'fotr-caravan': {
    title: 'FOTR Caravan',
    content: `
      This promotes reuse and recycling of used clothes and textile waste. By working and collaborating with various organizations, the FOTR caravan sets up bins in various locations around the country to collect donated clothes and waste trimmings. In 2019, the caravan was able to collect over 3,000 kilos of donated clothes and fabric trimmings donated to 200 street children in Mlango Kubwa, 300 girls in Mount Elgon who were FGM survivors and children in Gertrudes Hospital. In 2021, the caravan collected 1,000 kilos of donated clothes which were given to Langata Women's Prison. Partners - UNON, Naivas supermarkets, Langata Women's Prison, Karen Country Club and Balis restaurants.
    `,
    image: caravanImage,
  },
  'fotr-sports-series': {
    title: 'FOTR Sports Series',
    content: `
      Annual cultural charity sports tournament uniting fashion and sports communities.
    `,
    image: golfImage,
  },
  'fotr-conversations': {
    title: 'FOTR Conversations',
    content: `
      We have collaborated with people of influence to promote culture and the brand Kenya such as Connie Aluoch, Mwenesi Musalia, Deborah Sanaipei, Savara of Sauti Sol and Gina-Din Kariuki through social media and various e-commerce platforms. We have also collaborated with organizations such as Simba Corp Motors, Kenya Tourism Board (KTB) and Karen Country Club to host various fashion high teas such as the annual Marvellous Mums, an event that celebrates mothers and daughters. Fashion on the Road has provided access to the market by displaying Marini headpieces, accessories and fascinators made by Kenyatta University fashion students during various runway shows.
    `,
    image: conversationsImage,
  },
  'fotr-avps-ksbdc': {
    title: 'FOTR AVPS and KSBDC',
    content: `
      Carole Kinoti, is engaged in a dynamic agreement with Strathmore University, operating as the lead consultant to support the Strathmore Business School - USAID Strategic Partnerships Program under the Kenya Small Business Development Centers (SBDC) and Agency and Voice of the Private Sector (AVPS) Component's Clients and Stakeholders. Tasked with facilitating customized trainings, advisory, and coaching, Carole's responsibilities span from enhancing export market readiness to offering advisory services on finance access, technology development, and regulatory compliance. Carole is dedicated to developing comprehensive training programs, conducting market intelligence research, and providing policy recommendations. Her role also involves structuring production processes, pricing strategies, and optimizing various value chains for specified sectors and stakeholders.
    `,
    image: avpsImage,
  },
  'fotr-prada-unfpa': {
    title: 'FOTR- PRADA/ UNFPA',
    content: `
      In a remarkable collaboration, the Fashion on the Road joins forces with the Madonna Resource Centre to empower and inspire aspiring four female entrepreneurs sponsored by Prada. The Madonna Resource Centre, located in Githurai, is an initiative undertaken by the Sisters of the Immaculate Heart of Mary, who are driven by the Charism of the Compassion of Christ. Their mission is to uplift the dignity of women, children, youth, and vulnerable individuals within society. The Sisters are actively involved in various ministries, including healthcare, education, social services, and other endeavors aligned with their Charism. In this collaboration, Madonna had eleven ladies that assisted the selected Prada ladies in their internship and equally gained the same opportunity and impact from the internship program.
    `,
    image: pradaImage,
  },
};

const WhatWeDoDetail: React.FC = () => {
  const { id } = useParams();

  const detail = details[id as string];

  if (!detail) {
    return <div>Item not found</div>;
  }

  return (
    <>
      <Header />
      <section id="what-we-do-detail" className="what-we-do-detail mt-5">
        <div className="container">
          <div className="section-title">
            <h2>{detail.title}</h2>
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-12">
              <img src={detail.image} alt={detail.title} className="img-fluid" />
            </div>
            <div className="col-lg-6 col-md-12">
              <p>{detail.content}</p>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default WhatWeDoDetail;
