import React, { useState, useEffect } from 'react';
import './About.css';
import aboutImg1 from '../../assets/img/img6.072b32fc0ab57d602aac.png';
import aboutImg2 from '../../assets/img/img7.3f45884944c83fd0c1ba.png';
import aboutImg3 from '../../assets/img/post-8.png';
import aboutImg4 from '../../assets/img/post-9.png';
import aboutImg5 from '../../assets/img/post-7.png';

const images = [aboutImg1, aboutImg2, aboutImg3, aboutImg4, aboutImg5];

const About: React.FC = () => {
  const [currentImage, setCurrentImage] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImage((prevImage) => (prevImage + 1) % images.length);
    }, 3000); // Change image every 3 seconds

    return () => clearInterval(interval);
  }, []);

  return (
    <section id="about" className="about">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <img src={images[currentImage]} className="img-fluid main-img" alt="About Us" />
          </div>
          <div className="col-lg-6 pt-4 pt-lg-0">
            <h3>About Fashion on the Road (FOTR)</h3>
            <p>
              Fashion on the Road (FOTR) Programs are a transformative journey that redefines fashion. Our mission is clear: engage, uplift, and inspire. We empower women, people with disabilities, and youth through Made In Kenya fashion. Each step of our journey breathes life into used clothes and textile waste, promotes cultural appreciation, and fosters inclusivity, sustainability, and empowerment. Join us, where fashion sparks meaningful change.
            </p>
            <ul>
              <li><i className="bx bx-check-double"></i> Engaging and uplifting communities through fashion.</li>
              <li><i className="bx bx-check-double"></i> Promoting cultural appreciation and sustainability.</li>
              <li><i className="bx bx-check-double"></i> Empowering women, people with disabilities, and youth.</li>
            </ul>
            <div className="row icon-boxes">
              <div className="col-md-6">
                <i className="bx bx-receipt"></i>
                <h4>Inclusive Fashion</h4>
                <p>Empowering individuals through Made In Kenya fashion initiatives.</p>
              </div>
              <div className="col-md-6 mt-4 mt-md-0">
                <i className="bx bx-cube-alt"></i>
                <h4>Sustainable Practices</h4>
                <p>Promoting the use of recycled materials and reducing textile waste.</p>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-lg-3 col-6">
            <img src={aboutImg1} className="img-fluid uniform-dimensions" alt="Fashion Image 1" />
          </div>
          <div className="col-lg-3 col-6">
            <img src={aboutImg2} className="img-fluid uniform-dimensions" alt="Fashion Image 2" />
          </div>
          <div className="col-lg-3 col-6">
            <img src={aboutImg3} className="img-fluid uniform-dimensions" alt="Fashion Image 3" />
          </div>
          <div className="col-lg-3 col-6">
            <img src={aboutImg4} className="img-fluid uniform-dimensions" alt="Fashion Image 4" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
