import axios from 'axios';

// Use the environment variable for the API URL
const API_URL = process.env.REACT_APP_API_URL + '/subscribe';

interface SubscribeResponse {
  message: string;
}

export const subscribeNewsletter = async (email: string): Promise<SubscribeResponse> => {
  try {
    const response = await axios.post<SubscribeResponse>(API_URL, { email });
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
};
