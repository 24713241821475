import React from 'react';
import Header from '../components/header/Header';
import Collections from '../components/collections/Collections';
import Footer from '../components/footer/Footer';
import './CollectionsPage.css';

const CollectionsPage: React.FC = () => {
  return (
    <div className="collections-page">
      <Header />
      <Collections />
      <Footer />
    </div>
  );
};

export default CollectionsPage;
