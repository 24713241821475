import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import './PortfolioDetail.css';
import { fetchPortfolioById } from '../../services/portfolioService';

interface Params {
  id?: string;
}

const PortfolioDetail: React.FC = () => {
  const { id } = useParams() as Params; // Type assertion
  const navigate = useNavigate();
  const [portfolio, setPortfolio] = useState<any>(null);
  const [mainImage, setMainImage] = useState<string>('');
  const [currentIndex, setCurrentIndex] = useState<number>(0);

  useEffect(() => {
    if (!id) {
      navigate('/portfolio'); // Redirect to portfolio list if id is undefined
      return;
    }

    fetchPortfolioById(id).then(data => {
      setPortfolio(data);
      setMainImage(data.images[0]); // Set the first image as the main image initially
    });
  }, [id, navigate]);

  useEffect(() => {
    if (portfolio) {
      const interval = setInterval(() => {
        setCurrentIndex(prevIndex => {
          const newIndex = (prevIndex + 1) % portfolio.images.length;
          setMainImage(portfolio.images[newIndex]);
          return newIndex;
        });
      }, 3000); // Change image every 3 seconds

      return () => clearInterval(interval); // Clear interval on component unmount
    }
  }, [portfolio]);

  const handleThumbnailClick = (image: string, index: number) => {
    setMainImage(image);
    setCurrentIndex(index);
  };

  if (!portfolio) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <section id="breadcrumbs" className="breadcrumbs">
        <div className="container">
          <div className="d-flex justify-content-between align-items-center">
            <h2>Portfolio Details</h2>
            <ol>
              <li><a href="/">Home</a></li>
              <li>Portfolio Details</li>
            </ol>
          </div>
        </div>
      </section>

      <section id="portfolio-details" className="portfolio-details">
        <div className="container">
          <div className="row gy-4">
            <div className="col-lg-8">
              <div className="portfolio-main-image">
                <img src={mainImage} alt="Portfolio" />
              </div>
              <div className="portfolio-thumbnails">
                {portfolio.images.map((image: string, index: number) => (
                  <img
                    key={index}
                    src={image}
                    alt="Thumbnail"
                    onClick={() => handleThumbnailClick(image, index)}
                    className={mainImage === image ? 'active' : ''}
                  />
                ))}
              </div>
            </div>
            <div className="col-lg-4">
              <div className="portfolio-info">
                <h3>Project information</h3>
                <ul>
                  <li><strong>Category</strong>: {portfolio.category}</li>
                  <li><strong>Client</strong>: {portfolio.client}</li>
                  <li><strong>Project date</strong>: {new Date(portfolio.projectDate).toLocaleDateString()}</li>
                </ul>
              </div>
              <div className="portfolio-description">
                <h2>{portfolio.title}</h2>
                <p>{portfolio.description}</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PortfolioDetail;
