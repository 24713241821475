import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './Header.css';

const Header: React.FC = () => {
  const [activeSection, setActiveSection] = useState('hero');
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation(); // Use useLocation to get the current path

  useEffect(() => {
    const sections = document.querySelectorAll('section');
    const options = {
      threshold: 0.3,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setActiveSection(entry.target.id);
        }
      });
    }, options);

    sections.forEach((section) => {
      observer.observe(section);
    });

    return () => {
      sections.forEach((section) => {
        observer.unobserve(section);
      });
    };
  }, []);

  const handleNavClick = (e: React.MouseEvent<HTMLAnchorElement>, section: string) => {
    e.preventDefault();
    if (section === 'hero') {
      navigate('/');
    } else {
      document.getElementById(section)?.scrollIntoView({ behavior: 'smooth' });
    }
    setActiveSection(section);
    setIsMobileMenuOpen(false);
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const handleLogoClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    navigate('/');
  };

  return (
    <header id="header" className="fixed-top d-flex align-items-center">
      <div className="container d-flex align-items-center">
        <div className="logo me-auto">
          <a href="/" onClick={handleLogoClick}>
            <img src={`${process.env.PUBLIC_URL}/assets/img/logo2.png`} alt="FOTR Logo" className="img-fluid logo-img" />
          </a>
        </div>

        <nav id="navbar" className={`navbar order-last order-lg-0 ${isMobileMenuOpen ? 'navbar-mobile' : ''}`}>
          <ul>
            <li><a className={`nav-link scrollto ${activeSection === 'hero' ? 'active' : ''}`} href="/" onClick={(e) => handleNavClick(e, 'hero')}>Home</a></li>
            <li><a className={`nav-link scrollto ${activeSection === 'about' ? 'active' : ''}`} href="#about" onClick={(e) => handleNavClick(e, 'about')}>About</a></li>
            <li><a className={`nav-link scrollto ${activeSection === 'portfolio' ? 'active' : ''}`} href="#portfolio" onClick={(e) => handleNavClick(e, 'portfolio')}>Traction</a></li>
            <li><a className={`nav-link scrollto ${activeSection === 'contact' ? 'active' : ''}`} href="#contact" onClick={(e) => handleNavClick(e, 'contact')}>Contact</a></li>
            <li className="dropdown">
              <a href="/collections" className={location.pathname === '/collections' ? 'active' : ''}>
                <span>Collections</span> <i className="bi bi-chevron-down"></i>
              </a>
              <ul>
                <li><a href="https://fotrshop.carolekinoti.co.ke/search?category=louis" target="_blank" rel="noopener noreferrer">FOTR Louis</a></li>
                <li><a href="https://fotrshop.carolekinoti.co.ke/search?category=mwenesi" target="_blank" rel="noopener noreferrer">FOTR Mwenesi</a></li>
                <li><a href="https://fotrshop.carolekinoti.co.ke/search?category=gina" target="_blank" rel="noopener noreferrer">FOTR Gina Din</a></li>
                <li><a href="https://fotrshop.carolekinoti.co.ke/search?category=lad" target="_blank" rel="noopener noreferrer">FOTR LAD</a></li>
                <li><a href="https://fotrshop.carolekinoti.co.ke/search?category=limited" target="_blank" rel="noopener noreferrer">FOTR Limited Edition</a></li>
              </ul>
            </li>
            <li><a className="nav-link" href="https://fotrshop.carolekinoti.co.ke/" target="_blank" rel="noopener noreferrer">Shop</a></li>
          </ul>
        </nav>

        <div className="header-social-links d-flex align-items-center">
          <a href="https://fotr.carolekinoti.co.ke/" target="_blank" rel="noopener noreferrer">
            <i className="bi bi-cart"></i>
          </a>
          <a href="https://www.facebook.com/carolekinoti/" target="_blank" rel="noopener noreferrer">
            <i className="bi bi-facebook"></i>
          </a>
          <a href="https://www.instagram.com/carole_kinoti/" target="_blank" rel="noopener noreferrer">
            <i className="bi bi-instagram"></i>
          </a>
          <a href="https://www.linkedin.com/in/carole-kinoti-177501127/" target="_blank" rel="noopener noreferrer">
            <i className="bi bi-linkedin"></i>
          </a>
          <i className="bi bi-list mobile-nav-toggle" onClick={toggleMobileMenu}></i>
        </div>
      </div>
    </header>
  );
};

export default Header;
