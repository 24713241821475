import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import PortfolioDetail from './components/portfolio/PortfolioDetail';
import PortfolioList from './components/portfolio/PortfolioList';
import CollectionsPage from './pages/CollectionsPage';
import WhatWeDoDetail from './components/whatWeDo/WhatWeDoDetails';
import { PortfolioProvider } from './context/PortfolioContext';


const App: React.FC = () => {
  return (
    <PortfolioProvider>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/portfolio/:id" element={<PortfolioDetail />} />
          <Route path="/portfolio" element={<PortfolioList />} />
          <Route path="/collections" element={<CollectionsPage />} /> {/* Add this line */}
          <Route path="/what-we-do/:id" element={<WhatWeDoDetail />} />
        </Routes>
      </Router>
    </PortfolioProvider>
  );
}

export default App;
