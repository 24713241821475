import axios, { AxiosResponse, AxiosError } from 'axios';

interface ContactFormData {
  name: string;
  email: string;
  subject: string;
  message: string;
}

interface ContactResponse {
  message: string;
}

interface ErrorResponse {
  message: string;
}

// Use the environment variable for the API URL
const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:5888/api/contact';

export const submitContactForm = async (formData: ContactFormData): Promise<ContactResponse> => {
  try {
    const response: AxiosResponse<ContactResponse> = await axios.post(`${API_URL}/submit`, formData);
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError<ErrorResponse>;
      if (axiosError.response) {
        throw new Error(axiosError.response.data.message);
      }
    }
    throw new Error('An unexpected error occurred.');
  }
};
