import React, { useState, FormEvent } from 'react';
import './Footer.css';
import logo from '../../assets/img/logo.png';
import { subscribeNewsletter } from '../../services/subscribeService';

const Footer: React.FC = () => {
  const [email, setEmail] = useState<string>('');
  const [message, setMessage] = useState<string>('');

  const handleSubscribe = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const response = await subscribeNewsletter(email);
      setMessage(response.message);
      setEmail('');
    } catch (error: any) {
      setMessage(error.message);
    }
  };

  return (
    <footer id="footer">
      <div className="footer-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-6 footer-contact text-center">
              <img src={logo} alt="Logo" className="footer-logo" />
              <p>
                <strong>Phone:</strong> +254 792769097<br />
                <strong>Email:</strong> enquiries@carolekinoti.co.ke<br />
              </p>
            </div>

            <div className="col-lg-2 col-md-6 footer-links">
              <h4>Useful Links</h4>
              <ul>
                <li><i className="bx bx-chevron-right"></i> <a href="#">Home</a></li>
                <li><i className="bx bx-chevron-right"></i> <a href="#">About us</a></li>
                <li><i className="bx bx-chevron-right"></i> <a href="#">Services</a></li>
                <li><i className="bx bx-chevron-right"></i> <a href="#">Terms of service</a></li>
                <li><i className="bx bx-chevron-right"></i> <a href="#">Privacy policy</a></li>
              </ul>
            </div>

            <div className="col-lg-4 col-md-6 footer-newsletter ms-auto">
              <h4>Join Our Newsletter</h4>
              <p>Subscribe to stay updated on the latest news, events, and fashion trends from Fashion on the Road (FOTR).</p>
              <form onSubmit={handleSubscribe}>
                <input 
                  type="email" 
                  name="email" 
                  placeholder="Enter your email" 
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required 
                />
                <input type="submit" value="Subscribe" />
              </form>
              {message && <p>{message}</p>}
            </div>
          </div>
        </div>
      </div>

      <div className="footer-bottom">
        <div className="container text-center">
          <div className="copyright">
            &copy; Copyright <strong><span>FOTR</span></strong>. All Rights Reserved
          </div>
          <div className="credits">
            Designed by <a href="https://circularityspace.com">Circularity Space</a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
