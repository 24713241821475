import React from 'react';
import Header from '../components/header/Header';
import Hero from '../components/hero/Hero';
import WhatWeDo from '../components/whatWeDo/WhatWeDo';
import About from '../components/about/About';
import Counts from '../components/counts/Counts';
import Services from '../components/services/Services';
import Portfolio from '../components/portfolio/Portfolio';
import Testimonials from '../components/testimonials/Testimonials';
import Team from '../components/team/Team';
import Contact from '../components/contacts/Contact';
import Footer from '../components/footer/Footer';
import AppHero from '../components/apphero/AppHero'; // Import AppHero component
import './Home.css';

const Home: React.FC = () => {
  return (
    <div className="home-page">
      <Header />
      <Hero />
      <AppHero /> {/* Add AppHero component */}
      <WhatWeDo />
      <About />
      <Counts />
      <Portfolio />
      <Team />
      <Contact />
      <Footer />
    </div>
  );
};

export default Home;
