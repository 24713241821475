import React, { useEffect } from 'react';
import './Counts.css';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTshirt, faAward, faRecycle, faUserFriends } from '@fortawesome/free-solid-svg-icons';
import PureCounter from '@srexi/purecounterjs';

library.add(faTshirt, faAward, faRecycle, faUserFriends);

const Counts: React.FC = () => {
  useEffect(() => {
    new PureCounter(); // Initialize the pure counter
  }, []);

  return (
    <section id="counts" className="counts">
      <div className="container">
        <div className="row">
          <div className="col-lg-3 col-6">
            <div className="count-box">
              <FontAwesomeIcon icon="tshirt" />
              <span data-purecounter-start="0" data-purecounter-end="20" data-purecounter-duration="1" className="purecounter"></span>
              <p>Collections</p>
            </div>
          </div>

          <div className="col-lg-3 col-6">
            <div className="count-box">
              <FontAwesomeIcon icon="award" />
              <span data-purecounter-start="0" data-purecounter-end="5" data-purecounter-duration="1" className="purecounter"></span>
              <p>Awards</p>
            </div>
          </div>

          <div className="col-lg-3 col-6 mt-5 mt-lg-0">
            <div className="count-box">
              <FontAwesomeIcon icon="recycle" />
              <span data-purecounter-start="0" data-purecounter-end="15000" data-purecounter-duration="1" className="purecounter"></span>
              <p>Tons of Recycled Textile</p>
            </div>
          </div>

          <div className="col-lg-3 col-6 mt-5 mt-lg-0">
            <div className="count-box">
              <FontAwesomeIcon icon="user-friends" />
              <span data-purecounter-start="0" data-purecounter-end="2500" data-purecounter-duration="1" className="purecounter"></span>
              <p>Transformed Lives</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Counts;
