import React from 'react';
import './AppHero.css';
import iphoneImage1 from '../../assets/img/phone1.png'; // Replace with your actual image paths
import iphoneImage2 from '../../assets/img/phone2.png'; // Replace with your actual image paths

const AppHero: React.FC = () => {
  return (
    <section id="app-hero" className="app-hero">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-12">
            <div className="app-hero-text">
              <h1>Join Our Clothes Donation Drive</h1>
              <p>
                Help us make a difference in the lives of flood victims by donating clothes through our app. Your support can bring warmth and comfort to those in need.
              </p>
              <div className="download-buttons">
                <a href="https://play.google.com/store/apps/details?id=web.fotrapp.app&pcampaignid=web_share" className="btn btn-primary" target="_blank" rel="noopener noreferrer">
                  <i className="bi bi-google-play"></i> Download for Android
                </a>
                <a href="#" onClick={() => window.location.reload()} className="btn btn-primary">
                  <i className="bi bi-apple"></i> Download for iOS
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-12">
            <div className="app-hero-images">
              <img src={iphoneImage1} alt="App Screenshot 1" className="img-fluid" />
              <img src={iphoneImage2} alt="App Screenshot 2" className="img-fluid hide-on-small-screen" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AppHero;
