import React from 'react';
import './Team.css';
import caroleImage from '../../assets/img/team/carole.png';

const Team: React.FC = () => {
  return (
    <section id="team" className="team">
      <div className="container">
        <div className="section-title">
          <h2>Get to Know Us</h2>
        </div>

        <div className="row">
          <div className="col-lg-6 col-md-6 d-flex align-items-stretch">
            <div className="member">
              <img src={caroleImage} alt="Carole Kinoti" />
              <h4>Carole Kinoti</h4>
              <span>Founder</span>
              <div className="social">
                <a href="https://www.facebook.com/carolekinoti/" target="_blank" rel="noopener noreferrer">
                  <i className="bi bi-facebook"></i>
                </a>
                <a href="https://www.instagram.com/carole_kinoti/" target="_blank" rel="noopener noreferrer">
                  <i className="bi bi-instagram"></i>
                </a>
                <a href="https://www.linkedin.com/in/carole-kinoti-177501127/" target="_blank" rel="noopener noreferrer">
                  <i className="bi bi-linkedin"></i>
                </a>
              </div>
            </div>
          </div>

          <div className="col-lg-6 col-md-6 d-flex align-items-stretch">
            <div className="quote">
              <p>
                <i className="bi bi-quote quote-icon-left"></i>
                FOTR is tailored to use the runway as a tool for change, targeting women, people with disability and the youth. It goes around counties meeting them in their areas, engaging with them and adapting their different fashion cultural attributes into a design.
                <i className="bi bi-quote quote-icon-right"></i>
              </p>
              <p className="author">- Carole Kinoti</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Team;
